import React from "react";
import styled from "styled-components";

// Nested component imports

export const IconSuccess = styled.div`
  padding: 5px 2px 5px 3px;
  display: flex;
  align-items: flex-start;
`;
export const Fill = styled.img`
`;

const IconLoginComponent = ({className, children}) => {
  return (
    <IconSuccess className={className}>

      <Fill
        alt="fill"
        src="https://static.overlay-tech.com/assets/dddfbe5d-e851-4e94-adb2-422ceb7da3a4.png"
      />
    </IconSuccess>
  );
};

export default IconLoginComponent;