import { Link } from "gatsby";
import React from "react";
import styled from "styled-components";
import { StaticQuery, graphql } from "gatsby";
import { routeHelper } from "../../utils/routeHelper";
import { withPreview } from "@prismicio/gatsby-source-prismic-graphql";

export const FooterNavigation = (props) => (
  <StaticQuery
    query={query}
    render={withPreview(
      (data) => (
        <FooterNavigationComponent data={data} {...props} />
      ),
      query
    )}
  />
);
const handleDestructure = (data) => {
  const footer = data.prismic.allFooters.edges.map((x) => {
    const { ...rest } = x.node;

    return {
      ...rest,
    };
  });

  return {
    footer: footer,
  };
};

const handleFooterLink = (item) => {
  if (item.link?._meta?.uid)
    return `/${item.link?._meta?.uid.replace(/__/g, "/")}`;
  const route = item.link?.url;
  if (!route) return "/";
  if (route.includes(".")) {
    return route;
  }
  return route.split("https://")[1];
};

const FooterNavigationComponent = ({ setNavOpen, navOpen, data }) => {
  const { footer } = handleDestructure(data);
  const footerData = footer[0];
  return (
    <>
      <FooterWrapper
        className="d-flex justify-content-between"
        role="contentinfo"
        aria-label="Footer"
      >
        <FooterLink to="/">
          <AssetLogo alt="assetLogo" src={footerData?.footer_logo?.url} />
        </FooterLink>
        <div className="d-flex justify-content-between w-100 flex-wrap">
          {footerData?.body &&
            footerData.body.map((navigation_item) => {
              return (
                navigation_item && (
                  <ItemsWrapper>
                    <NavigationCategory>
                      {navigation_item?.primary?.navigation_category
                        ? navigation_item?.primary?.navigation_category[0].text
                        : ""}
                    </NavigationCategory>

                    {navigation_item.fields.map((nav_link) => {
                      return (
                        nav_link && (
                          <div className="d-flex">
                            <NavigationItem
                              className="d-flex flex-column"
                              href={routeHelper(handleFooterLink(nav_link))}
                              target={
                                nav_link.link?._meta?.uid ||
                                nav_link.link?.url?.includes("#formPanel")
                                  ? ""
                                  : "_blank"
                              }
                            >
                              {nav_link?.link_text
                                ? nav_link?.link_text[0].text
                                : ""}
                            </NavigationItem>
                          </div>
                        )
                      );
                    })}
                  </ItemsWrapper>
                )
              );
            })}
        </div>
      </FooterWrapper>
      <Border />
      <BottomItemsWrapper className="d-flex justify-content-between">
        <Policies className="d-flex align-items-end">
          <TermsConditionsText href="/Skildare Terms of Use of Website.pdf">
            Terms of use
          </TermsConditionsText>
          <TermsConditionsText href="/Skildare Privacy Policy.pdf" isLast={true}>
            Privacy policy
          </TermsConditionsText>
        </Policies>
        <LinkItems className="align-items-start">
          <TermsConditionsText href="/">© Skildare 2022</TermsConditionsText>
          <SeparatorText> - Made by </SeparatorText>
          <TermsConditionsText
            href="https://www.mudbath.com.au"
            target="_blank"
            colorMode={"Bold"}
          >
            Mudbath
          </TermsConditionsText>
        </LinkItems>
      </BottomItemsWrapper>
    </>
  );
};

const Policies = styled.div`
  @media only screen and (max-width: 1200px) {
    flex-direction: row;
    justify-content: space-between;
  }
`;
const LinkItems = styled.div``;

const BottomItemsWrapper = styled.div`
  margin-bottom: 24px;
  max-width: 1200px;
  margin: 0 auto 16px auto;
  background-color: white;
  flex-direction: row-reverse;

  @media only screen and (max-width: 1200px) {
    flex-direction: column;
    padding: 0 24px;
  }
`;

const Border = styled.div`
  max-width: 1200px;
  width: 100%;
  height: 1px;
  margin: 0 auto;
  background-color: ${({ theme }) => theme.colors.navyPrimary};
  margin-bottom: 16px;
  opacity: 0.2;
`;

const FooterLink = styled(Link)`
  margin-left: 306px;

  @media only screen and (max-width: 1200px) {
    margin: 0;
    align-items: start;
    padding-bottom: 32px;
  }
`;

const AssetLogo = styled.img`
  width: 91px;
  height: 30px;
  @media only screen and (max-width: 1200px) {
    width: 73px;
    height: 24px;
  }
`;

const FooterWrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding-bottom: 62px;
  flex-direction: row-reverse;
  background-color: white;

  @media only screen and (max-width: 1200px) {
    flex-direction: column;
    padding: 0 24px;
  }
`;

const ItemsWrapper = styled.div`
  @media only screen and (max-width: 800px) {
    margin-bottom: 24px;
    margin-right: 64px;
  }

  @media only screen and (min-width: 600px) {
    padding-right: 30px;
  }
`;

const NavigationCategory = styled.h5`
  font-family: Lustria;
  font-size: 20px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.grey};
  padding-bottom: 32px;

  max-width: 245px;
`;

const NavigationItem = styled.a`
  font-family: Lustria;
  width: auto;
  font-size: 16px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.grey};
  padding-bottom: 16px;
  text-decoration: none;
  :hover {
    color: ${({ theme }) => theme.textColors.steelBlue};
    cursor: pointer;
  }
`;

const TermsConditionsText = styled.a`
  font-family: ${(props) => props.theme.fonts.buttonLeftWhite.family};
  font-size: 14px;
  line-height: 20px;
  color: ${(props) => props.theme.colors.grey};
  ${({ isLast }) => (isLast ? `margin-left: 16px;` : "")}
  opacity: 0.5;
  text-align: center;
  text-decoration: none;
  :hover {
    text-decoration: underline;
    color: ${(props) => props.theme.colors.navyPrimary};
    opacity: 0.6;
  }
`;

const SeparatorText = styled.span`
  font-family: ${(props) => props.theme.fonts.buttonLeftWhite.family};
  font-size: 14px;
  line-height: 20px;
  color: ${(props) => props.theme.colors.grey};
  opacity: 0.5;
`;

export const query = graphql`
  {
    prismic {
      allFooters {
        edges {
          node {
            footer_logo
            body {
              ... on PRISMIC_FooterBodyFooter_group {
                type
                label
                primary {
                  navigation_category
                }
                fields {
                  link {
                    ... on PRISMIC__ExternalLink {
                      url
                      _linkType
                    }
                    ... on PRISMIC_Page {
                      _linkType
                      _meta {
                        uid
                      }
                    }
                    ... on PRISMIC__FileLink {
                      _linkType
                      url
                    }
                  }
                  link_text
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default FooterNavigationComponent;
