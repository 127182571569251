/* This is the project stylesheet */

export default {
  colors: {
    greenBackground: "rgba(230,243,219,1)",
    lightGreenBackground: "rgba(36, 168, 102, 0.1)",
    greenTransparent4: "rgba(1,50,61,0.04)",
    greyBackground: "rgba(245,247,247,1)",
    navyPrimary: "rgba(0,50,61,1)",
    green: "rgb(36, 168, 102)",
    greenTransparent: "rgb(36, 168, 102, 0.1)",
    navyBackgroundHover: "rgba(35, 92, 101, 1)",
    navyBackgroundPressed: "rgba(93, 133, 140, 1)",
    lightButtonHover: "#E6F2F4",
    lightButtonPressed: "#E6F2F4",
    boxShadow: "rgba(0, 0, 0, 0.16)",
    whiteSecondary: "rgba(250, 251, 252, 1)",
    navyTransparent: "rgba(0,51,61,0.15)",
    blueGreen: "#00A4C4",
    darkBlue: "#367D89",
    grey: "#424242",
    steelBlue: "#4597A5",
    mainBlue: "rgba(0, 51, 61, 1)",
    white: "rgba(255, 255, 255, 1)",
    whiteOpacity_02: "rgba(255, 255, 255, 0.8)",
    steelBlueOpacity_01: "rgba(69, 151, 165, 0.1)",
    greyOpacity_01: "rgba(107, 107, 106, 0.1)",
    formFieldBorder: "rgba(216, 225, 227, 1)",
  },
  textColors: {
    greenPrimary: "rgba(37,168,102,1)",
    white: "rgba(255,255,255,1)",
    greySecondary: "#6b6b6a",
    grey: "#424242",
    red: "rgba(208, 2, 27, 1)",
    steelBlue: "#4597A5",
  },
  fonts: {
    buttonLeftBlack: {
      family: "Agenda",
      size: "20px",
      weight: "400",
      lineHeight: "24px",
    },
    buttonLeftBlackSm: {
      family: "Agenda",
      size: "16px",
      weight: "400",
      lineHeight: "24px",
    },
    buttonLeftWhite: {
      family: "OpenSans",
      size: "18px",
      weight: "400",
      lineHeight: "24px",
    },
    desktop1HeadingXxlBlackDefaultCentre: {
      family: "Lustria",
      size: "39px",
      weight: "400",
      lineHeight: "48px",
    },
    desktop2HeadingXlBlackDefaultLeft: {
      family: "Lustria",
      size: "39px",
      weight: "400",
      lineHeight: "48px",
    },
    desktop4HeadingMBlackDefaultLeft: {
      family: "Lustria",
      size: "25px",
      weight: "400",
      lineHeight: "32px",
    },
    desktopTextLargeBlackDefaultLeft: {
      family: "OpenSans",
      size: "20px",
      weight: "400",
      lineHeight: "28px",
    },
    formFieldInput: {
      family: "OpenSans",
      size: "18px",
      weight: "400",
      lineHeight: "24px",
    },
    formFieldPlaceholder: {
      family: "OpenSans",
      size: "16px",
      weight: "400",
      lineHeight: "24px",
    },
  },
};
