import React from "react";

import styled from "styled-components";
import { routeHelper } from "../../../utils/routeHelper";

import IconLogin from "./IconLoginComponent";

export const ButtonLargeSecondaryIconLeft = styled.a`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 4px;
  padding: 10px 15px 11px 11px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border: 1px solid ${({ theme }) => theme.colors.mainBlue};
  text-decoration: none;
`;

export const Text = styled.p`
  font-family: ${(props) => props.theme.fonts.buttonLeftWhite.family};
  font-size: ${(props) => props.theme.fonts.buttonLeftWhite.size};
  font-weight: ${(props) => props.theme.fonts.buttonLeftWhite.weight};
  line-height: ${(props) => props.theme.fonts.buttonLeftWhite.lineHeight};
  color: ${({ theme }) => theme.colors.mainBlue};
`;

const ButtonLargeSecondaryIconLeftComponent = ({
  className,
  children,
  href,
}) => {
  return (
    <ButtonLargeSecondaryIconLeft
      className={className}
      href={routeHelper(href)}
    >
      <IconLogin />
      <Text>Login </Text>
    </ButtonLargeSecondaryIconLeft>
  );
};

export default ButtonLargeSecondaryIconLeftComponent;
