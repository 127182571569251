import React from "react"

import styled from "styled-components"

// Nested component imports

export const ButtonLargePrimary = styled.button`
  background-color: ${(props) => props.theme.colors.navyPrimary};
  border-radius: 4px;
  padding: 11px 16px 13px;
  display: flex;
  align-items: flex-start;
  font-family: ${(props) => props.theme.fonts.buttonLeftWhite.family};
  font-size: ${(props) => props.theme.fonts.buttonLeftWhite.size};
  font-weight: ${(props) => props.theme.fonts.buttonLeftWhite.weight};
  line-height: ${(props) => props.theme.fonts.buttonLeftWhite.lineHeight};
  color: ${(props) => props.theme.textColors.white};
  text-align: center;
  text-decoration: none;
  cursor: pointer;
`

const ButtonLargePrimaryComponent = ({
  className,
  children,
  href,
  onClick,
}) => {
  const goToFormPanel = () => {
    window.document.getElementById("formPanel").scrollIntoView({
      block: "start",
      behavior: "smooth",
    })
  }

  return (
    <ButtonLargePrimary
      className={className}
      onClick={onClick ? onClick : goToFormPanel}
    >
      {children}
    </ButtonLargePrimary>
  )
}

export default ButtonLargePrimaryComponent
