import React from "react"

const CrossIcon = () => (
  <svg
    width="32px"
    height="33px"
    viewBox="0 0 32 33"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>D0E3A503-58F9-405A-AC58-9C5EB949334C</title>
    <defs>
      <filter color-interpolation-filters="auto" id="filter-1">
        <feColorMatrix
          in="SourceGraphic"
          type="matrix"
          values="0 0 0 0 0.000000 0 0 0 0 0.200000 0 0 0 0 0.239216 0 0 0 1.000000 0"
        ></feColorMatrix>
      </filter>
    </defs>
    <g id="Blog" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g
        transform="translate(-350.000000, -25.000000)"
        filter="url(#filter-1)"
      >
        <g
          transform="translate(350.000000, 25.290000)"
          id="Icon/Close"
          fill="#03100A"
        >
          <path
            d="M25.8761424,6.12385763 C25.342809,5.59052429 24.542809,5.59052429 24.0094757,6.12385763 L16,14.1333333 L7.99052429,6.12385763 C7.45719096,5.59052429 6.65719096,5.59052429 6.12385763,6.12385763 C5.59052429,6.65719096 5.59052429,7.45719096 6.12385763,7.99052429 L14.1333333,16 L6.12385763,24.0094757 C5.59052429,24.542809 5.59052429,25.342809 6.12385763,25.8761424 C6.39052429,26.142809 6.65719096,26.2761424 7.05719096,26.2761424 C7.45719096,26.2761424 7.72385763,26.142809 7.99052429,25.8761424 L16,17.8666667 L24.0094757,25.8761424 C24.2761424,26.142809 24.6761424,26.2761424 24.942809,26.2761424 C25.2094757,26.2761424 25.6094757,26.142809 25.8761424,25.8761424 C26.4094757,25.342809 26.4094757,24.542809 25.8761424,24.0094757 L17.8666667,16 L25.8761424,7.99052429 C26.4094757,7.45719096 26.4094757,6.65719096 25.8761424,6.12385763 Z"
          ></path>
        </g>
      </g>
    </g>
  </svg>
)

export default CrossIcon
