import React from "react";
import styled from "styled-components";
import { useState } from "react";
import { routeHelper } from "../../../utils/routeHelper";

const DropDown = ({ fields, isDesktop = true }) => {
  const [openNavItem, setOpenNavItem] = useState(-1);

  const mapNavigationLink = (navigation) => {
    if (navigation?._meta?.type === 'case_study') {
      return `/case-studies/${navigation?._meta?.uid}`
    };
    return navigation?._meta?.uid
      ? `/${navigation?._meta?.uid.replace(/__/g, "/")}`
      : navigation?.url
  }

  if (isDesktop)
    return (
      <>
        {fields.map((item, index) => {
          return (
            <TextWrapper
              isHighligted={openNavItem === index}
              isLast={index === fields.length - 1}
              onMouseEnter={() => setOpenNavItem(index)}
              onMouseLeave={() => setOpenNavItem(-1)}
            >
              <DropDownText
                href={routeHelper(mapNavigationLink(item.navigation_link))}
                target={item.navigation_link?._meta?.uid ? "" : "_blank"}
              >
                {item.title ? item?.title[0]?.text : ""}
              </DropDownText>
            </TextWrapper>
          );
        })}
      </>
    );
  return (
    <>
      {fields.map((item, index) => {
        return (
          <TextWrapper>
            <DropDownTextMobile
              href={routeHelper(mapNavigationLink(item.navigation_link))}
              target={item.navigation_link?._meta?.uid ? "" : "_blank"}
            >
              {item?.title ? item?.title[0].text : ""}
            </DropDownTextMobile>
          </TextWrapper>
        );
      })}
    </>
  );
};

const TextWrapper = styled.div`
  display: flex;
  align-items: center;
  @media only screen and (max-width: 1200px) {
    padding-left: 82px;
  }
`;

const DropDownText = styled.a`
  text-decoration: none;
  margin: 12px 16px;
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme.textColors.steelBlue};
  font-family: "OpenSans";
  white-space: pre-line;
  :hover {
    color: ${({ theme }) => theme.textColors.steelBlue};
    text-decoration: underline;
  }
`;

const DropDownTextMobile = styled(DropDownText)`
  font-size: 16px !important;
  margin-left: 0;
`;

export default DropDown;
