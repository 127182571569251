import React, { useState } from "react";
import styled from "styled-components";
import { RichText } from "prismic-reactjs";
import CaretDownIcon from "../../icons/CaretDown";
import CaretUpIcon from "../../icons/CaretUp";
import ChevronDownIcon from '../../icons/ChevronDown';
import ChevronUpIcon from '../../icons/ChevronUp';
import DropDown from "./HeaderDropdown";
import {
  NavItem,
  NavLink,
  MobileIcon,
  MobileNavigationText,
} from "../NavigationDesktopComponent";
import { routeHelper } from "../../../utils/routeHelper";

const handleTextItemLink = (item) => {
  if (item.primary.navigation_link?._meta?.uid)
    return `/${item.primary.navigation_link?._meta?.uid.replace(/__/g, "/")}`;
  const route = item.primary.navigation_link?.url;
  if (!route) return "/";

  if (route.includes(".")) {
    return route;
  }
  return route.split("https://")[1];
};

const NavTextItem = ({
  navigation_item,
  index,
  isLast,
  isDesktop = true,
  darkMode,
}) => {
  const [dropDownActive, setDropDownActive] = useState(false);
  if (isDesktop)
    return (
      <TextWrapper
        className="d-flex flex-row align-items-center position-relative justify-content-space-between "
        isLast={isLast}
        onMouseEnter={() => setDropDownActive(true)}
        onMouseLeave={() => setDropDownActive(false)}
      >
        <HeaderLink
          darkMode={darkMode}
          href={routeHelper(handleTextItemLink(navigation_item))}
          className="float-right"
        >
          {RichText.asText(navigation_item.primary.title)}
        </HeaderLink>
        {navigation_item.fields.length > 0 && (
          <DropDownWrapper
            className={`${dropDownActive ? "d-flex" : "d-none"
              } flex-column position-absolute`}
          >
            <DropDown {...navigation_item} darkMode={darkMode} />
          </DropDownWrapper>
        )}
      </TextWrapper>
    );
  return (
    <NavItem
      className="border-bottom"
      onClick={() => setDropDownActive(!dropDownActive)}
    >
      <div className="flex-column">
        <MobileItemWrapper className="d-flex flex-row align-items-center">
          <NavLink href={routeHelper(handleTextItemLink(navigation_item))}>
            <MobileLinkWrapper className="d-flex flex-row align-items-center">
              <MobileIcon
                alt="assetLogo"
                src={navigation_item.primary.navigation_icon_link?.url}
              />{" "}
              <MobileNavigationText>
                {RichText.asText(navigation_item.primary?.title)}
              </MobileNavigationText>
            </MobileLinkWrapper>
          </NavLink>
          <IconWrapper>
            {dropDownActive && navigation_item.fields.length > 0 && <UpChevron />}
            {!dropDownActive && navigation_item.fields.length > 0 && (
              <DownChevron />
            )}
          </IconWrapper>
        </MobileItemWrapper>
        {navigation_item.fields.length > 0 && (
          <MobileDropDownWrapper
            className={`${dropDownActive ? "d-flex" : "d-none"
              } flex-column align-items-start `}
          >
            <DropDown {...navigation_item} isDesktop={false} />
          </MobileDropDownWrapper>
        )}
      </div>
    </NavItem>
  );
};

const MobileLinkWrapper = styled.div`
  margin-top: 4px;
`;

const MobileDropDownWrapper = styled.div`
  margin-top: -20px;
  margin-bottom: 10px;
`;

const TextWrapper = styled.div`
  margin: 0 auto;
  margin-right: ${({ isLast }) => {
    return isLast ? "24px" : "0";
  }};
  @media only screen and (max-width: 1200px) {
    display: none;
  }
`;

const DropDownWrapper = styled.div`
  width: 245px;
  top: 19px;
  left: 20px;
  margin-top: 8px;
  transform: translateX(-10%);
  background-color: white;
  box-shadow: 0px 0px 24px 0px ${({ theme }) => theme.colors.boxShadow};
  border-radius: 4px;
`;

const MobileItemWrapper = styled.div`
  justify-content: space-between;
`;

const IconWrapper = styled.div`
  align-items: center;
  margin-right: 15px;
`;

const UpCaret = styled(CaretUpIcon)`
  padding-left: 6px;
  margin-left: 100px;
  align-items: center;
  margin-left: 100px;
`;

const UpChevron = styled(ChevronUpIcon)`
  padding: 10px;
  margin-left: 100px;
  align-items: center;
  margin-left: 100px;
`;

const DownCaret = styled(CaretDownIcon)`
  padding-left: 6px;
  align-items: center;
  margin-left: 100px;
`;

const DownChevron = styled(ChevronDownIcon)`
  padding: 10px;
  align-items: center;
  margin-left: 100px;
`;

const HeaderLink = styled.a`
  text-decoration: none;
  font-size: 20px;
  line-height: 24px;
  transition: all 0.3s;
  color: ${({ theme, darkMode }) =>
    darkMode ? theme.textColors.white : theme.colors.grey};
  font-family: "Lustria";
  margin: 0 0;

  :hover {
    color: ${({ theme, darkMode }) =>
    darkMode ? theme.textColors.white : theme.colors.grey};
  }
`;

export default NavTextItem;
