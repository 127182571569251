import React from "react";
import { SvgWrapper } from "./CaretDown";

const ChevronUp = () => (
  <SvgWrapper>
    <svg viewBox="100.024 180.022 21.213 12.021" width="21.213" height="12.021" xmlns="http://www.w3.org/2000/svg">
      <title>Chevron - Up</title>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M 121.237 181.437 L 110.63 192.043 L 100.024 181.437 L 101.438 180.022 L 110.63 189.215 L 119.823 180.022 L 121.237 181.437 Z" fill="#4597A5" transform="matrix(-1, 0, 0, -1, 221.261002, 372.065002)" />
    </svg>
  </SvgWrapper>
);

export default ChevronUp;
