import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { FaBars } from "react-icons/fa";
import { StaticQuery, graphql } from "gatsby";
import ButtonLargePrimaryComponent from "./components/ButtonLargePrimaryComponent";
import ButtonLargeSecondaryIconLeft from "./components/ButtonLargeSecondaryIconLeftComponent";
import CrossIcon from "../icons/Cross";
import { HeaderTypes } from "../models/prismicTypes";
import NavTextItem from "./components/NavigationTextItem";
import { withPreview } from "@prismicio/gatsby-source-prismic-graphql";

export const DesktopNavigation = (props) => (
  <StaticQuery
    query={query}
    render={withPreview(
      (data) => (
        <NavigationDesktopComponent data={data} {...props} />
      ),
      query
    )}
  />
);

const handleDestructure = (data, darkMode) => {
  const navItems = data.prismic.allHeaders.edges.flatMap((x) => {
    return x.node.body.filter((data) => data.type === HeaderTypes.navItem);
  });
  const navButtonItems = data.prismic.allHeaders.edges.flatMap((x) => {
    return x.node.body.filter(
      (data) => data.type === HeaderTypes.navButtonItem
    );
  });
  const logo = data.prismic.allHeaders.edges[0].node.header_logo?.url;
  const secondaryLogo =
    data.prismic.allHeaders.edges[0].node.header_logo_secondary?.url;

  return {
    headerData: navItems,
    headerButtons: navButtonItems,
    headerLogo: darkMode ? secondaryLogo : logo,
  };
};

export const handleLink = (item) => {
  if (item.navigation_button_link?._meta?.uid)
    return `${item.navigation_button_link?._meta?.uid.replace(/__/g, "/")}`;
  const route = item.navigation_button_link?.url;
  if (!route) return "/";
  if (route.includes(".")) {
    return route;
  }
  return route.split("https://")[1];
};

const NavigationDesktopComponent = ({ setNavOpen, navOpen, data, hideNavMenu }) => {
  const [offset, setOffset] = useState(0);

  useEffect(() => {
    const onScroll = () => setOffset(window.pageYOffset);
    // clean up code
    window.removeEventListener("scroll", onScroll);
    window.addEventListener("scroll", onScroll, { passive: true });
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  const darkMode = offset > 150;
  const isMobile =
    typeof window !== "undefined" ? window.outerWidth < 1200 : false;

  const handleNavToggle = () => {
    setNavOpen(!navOpen);
  };

  const { headerData, headerButtons, headerLogo } = handleDestructure(
    data,
    darkMode && !isMobile
  );
  if (!headerData && !headerButtons) return null;


    let headerRow;

    if(hideNavMenu) {
      headerRow = '';
    } else {
      headerRow = <HeaderRow>
              {headerData.map((navigation_item, index) => {
                return (
                  <NavTextItem
                    darkMode={darkMode && !isMobile}
                    navigation_item={navigation_item}
                    index={index}
                    isLast={index === headerData.length - 1}
                  />
                );
              })}
            </HeaderRow>
    }


  return (
    <>
      <Nav id="navigationBar" darkMode={darkMode && !isMobile}>
        <NavigationDesktop className="py-3 px-0 my-0 mx-auto">
          <LogoWrapper className="d-flex">
            <HeaderLink href="/" className="ms-0 mx-4">
              <AssetLogo alt="assetLogo" src={headerLogo} />
            </HeaderLink>
            {headerRow}
          </LogoWrapper>
          <HamburgerMenuWrapper className="d-block d-xl-none" navOpen={navOpen}>
            <button className="btn p-0" onClick={handleNavToggle}>
              {navOpen ? <CrossIcon /> : <FaBars size="32px" />}
            </button>
          </HamburgerMenuWrapper>
        </NavigationDesktop>
      </Nav>
      {navOpen && (
        <MoblieNavMenu navOpen={navOpen} setNavOpen={setNavOpen}>
          {headerData.map((navigation_item) => {
            return (
              <NavTextItem
                navigation_item={navigation_item}
                isDesktop={false}
              />
            );
          })}
        </MoblieNavMenu>
      )}
    </>
  );
};

const HamburgerMenuWrapper = styled.div`
  padding-right: 24px;
`;
export const MobileNavigationText = styled.span`
  font-family: Lustria;
  font-size: 20px;
  line-height: 32px;
  margin-left: 18px;
  color: ${({ theme }) => theme.textColors.grey};
`;
export const MobileIcon = styled.img`
  width: 24px;
  height: 24px;
  margin: 24px 0 24px 40px;
`;

const HeaderRow = styled.div`
  width: 100%;
  justify-content: space-between;
  display: none;
  @media only screen and (min-width: 1200px) {
    display: flex;
  }
`;

export const Nav = styled.div`
  position: fixed;
  transition: all 0.4s;
  width: 100%;
  z-index: 200;
  background-color: ${({ theme, darkMode }) =>
    darkMode ? theme.colors.grey : theme.colors.whiteSecondary};
`;

export const NavigationDesktop = styled.div`
  background-color: transparent;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  max-width: 1200px;

  @media only screen and (max-width: 1200px) {
    background-color: white;
    border-radius: 0 0 24px 24px;
    box-shadow: 0px 0px 24px 0px ${({ theme }) => theme.colors.boxShadow};
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 28px 24px;
    max-height: 80px;
  }
`;
export const MainGroup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 1576px;
`;
export const AssetLogo = styled.img`
  width: 110px;
  height: 30px;
  @media only screen and (max-width: 1200px) {
    width: 90px;
    height: 24px;
  }
`;
export const ButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;
export const ContactBtn = styled(ButtonLargePrimaryComponent)`
  &:not(:last-of-type) {
    margin-right: 24px;

    @media only screen and (max-width: 1200px) {
      &:not(:last-of-type) {
        margin-right: 16px;
      }
    }
  }
`;
export const LoginBtn = styled(ButtonLargeSecondaryIconLeft)`
  &:not(:last-of-type) {
    margin-right: 24px;
    @media only screen and (max-width: 1200px) {
      &:not(:last-of-type) {
        margin-right: 16px;
      }
    }
  }
`;

const LogoWrapper = styled.div`
  width: 100%;
  align-items: center;
`;

const HeaderLink = styled.a`
  text-decoration: none;
  padding-left: 24px;
  font-size: 20px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.navyPrimary};
  font-family: "Agenda-Semibold";
  margin: 0 24px;
  cursor: pointer;
  :hover {
    color: ${({ theme }) => theme.textColors.greenPrimary};
  }
`;

const MoblieNavMenu = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100vh;
  z-index: 1;
  background-color: white;
  padding-top: 80px;
  @media only screen and (min-width: 1200px) {
    display: none;
  }
`;

export const NavItem = styled.div`
  a {
    color: ${({ theme }) => theme.colors.grey};
    text-decoration: none;
    font-family: Agenda;
    font-size: 25px;
    letter-spacing: 0;
  }
  border-color: ${({ theme }) => theme.colors.steelBlue} !important;
`;

export const NavLink = styled.a`
  height: 80px;
  align-items: center;
`;

const NavLinkMobile = styled(NavLink)`
  margin-top: 8px;
`;

export const query = graphql`
  {
    prismic {
      allHeaders {
        edges {
          node {
            header_logo
            header_logo_secondary
            body {
              ... on PRISMIC_HeaderBodyNavigation_item {
                type
                label
                primary {
                  navigation_icon_link {
                    ... on PRISMIC__ImageLink {
                      _linkType
                      url
                    }
                  }
                  navigation_link {
                    ... on PRISMIC_Page {
                      _linkType
                      _meta {
                        uid
                      }
                    }
                    ... on PRISMIC__ExternalLink {
                      _linkType
                      url
                    }
                    ... on PRISMIC_Blog_home {
                      _meta {
                        uid
                      }
                    }
                  }
                  title
                }
                fields {
                  navigation_link {
                    ... on PRISMIC_Page {
                      _linkType
                      _meta {
                        uid
                      }
                    }
                    ... on PRISMIC_Blog_home {
                      _meta {
                        uid
                      }
                    }
                    ... on PRISMIC__ExternalLink {
                      url
                    }
                    ... on PRISMIC_Case_study {
                      _meta {
                        uid
                        type
                      }
                    }
                  }
                  title
                }
              }
            }
          }
        }
      }
    }
  }
`;
export default NavigationDesktopComponent;
