import React from "react";
import { SvgWrapper } from "./CaretDown";

const ChevronDown = () => (
  <SvgWrapper>
    <svg width="22" height="13" viewBox="0 0 22 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <title>Chevron - Down</title>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M21.6131 1.72119L11.0065 12.3278L0.399902 1.72119L1.81412 0.306978L11.0065 9.49937L20.1989 0.306978L21.6131 1.72119Z" fill="#4597A5" />
    </svg>
  </SvgWrapper>
);

export default ChevronDown;
